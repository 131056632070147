<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div class="container-xl rn-column-area rn-section-gap bg_color--5 mt--150">
      <v-row>
     
        <v-col lg="4" md="4" sm="12" cols="12">
          <div class="single-column">
            <h2>Κατηγορίες Δώρων</h2>
            <ul
              class="menu custompozition4 mt--40"
              style="list-style-type: none;"
            >
              <div v-for="(category, i) in categoriesToShow" :key="i">
                <li style="padding: 5px;">
                  <p>
                    <img
                      :src="`${category.image}`"
                      width="30px"
                      style="display: inline-block;"
                    />
                    <a v-on:click="updateCategory(category.gift_category_id)">
                      {{ category.name.el }}
                      <span class="isGreyNumber"
                        >({{ category.total_gifts }})
                      </span>
                    </a>
                  </p>
                </li>
              </div>
              <li style="padding: 5px;"></li>
              <div
                style="border: 1px solid #004e96; border-radius:5px;width:80% "
                class="mt--50"
              >
                <li style="padding: 5px;">
                  <center>
                    <p>
                      <img
                        src="@/assets/images/logo/cloud_computing.png"
                        width="40px"
                        style="display: inline-block;"
                      />
                      <a
                        target="blank"
                        href="https://roadcube-production-files.s3.eu-central-1.amazonaws.com/files/ELIN_CATALOGUE_2023_FINAL_FOR+WEB.pdf"
                      >
                        Κατέβασε τον κατάλογο
                      </a>
                    </p>
                  </center>
                </li>
              </div>
              <!--  <a target="blank" href="/elin-catalog" color="white"> Κατέβασε τον κατάλογο </a>
                                        todo change url link of pdf
                                    <a target="blank" href="https://localhost:8080/ELIN_CATALOGUE_2021_DIGITAL_SPREADS.pdf" color="white"> Κατέβασε τον κατάλογο </a>
                            -->
            </ul>
          </div>
        </v-col>
        <v-col lg="4" md="4" sm="12" cols="12">
          <div
            v-if="gifts.gifts&&gifts.gifts.length"
            class="single-column ml_sm--10 ml_lg--100 ml_xl--100"
          >
            <div id="giftsList" v-for="(gift, i) in gifts.gifts" :key="i">
              <div class="mb--50">
                <img :src="`${gift.image}`" width="200px" />
                <h2>{{ gift.title }}</h2>
                <p class="isRed">{{ gift.points }} πόντοι</p>
                <p>{{ gift.description }}</p>
                <div v-if="user">
                  <b-button
                    v-if="gift.promo_type != 'product'"
                    @click="
                      gift.promo_type === 'product'
                        ? redeemProduct()
                        : redeemCoupon(gift.code)
                    "
                    :disabled="gift.points > user.total_points"
                    class="btn"
                    variant="primary"
                    >Εξαργύρωση</b-button
                  >
                  <p
                    class="isGrey"
                    v-if="
                      gift.points > user.total_points &&
                        gift.promo_type != 'product'
                    "
                  >
                    Δεν έχεις αρκετούς πόντους για αυτό το δώρο
                  </p>

                  <div v-if="gift.promo_type === 'product'">
                    <RedeemCouponForm
                      :code="gift.code"
                      :promo_type="gift.promo_type"
                      :points="gift.points"
                      :total_points="user.total_points"
                      :dialog="false"
                    />
                  </div>
                </div>
              </div>

              <br /><br />
            </div>
          </div>
          <div v-else class="single-column ml_sm--10 ml_lg--100 ml_xl--100">
            <span class="text-subtitle-1">Δεν βρέθηκαν αποτελέσματα</span>
          </div>

          <b-pagination
            v-if="
              this.gifts.pagination && this.gifts.pagination.total_pages > 1
            "
            v-model="currentPage"
            :per-page="this.gifts.pagination.items_per_page"
            :total-rows="this.gifts.pagination.total"
            v-on:click.native="scrollToTop"
            aria-controls="giftsList"
            align="center"
          ></b-pagination>
        </v-col>

        <v-col lg="4" md="4" sm="12" cols="12">
          <div class="single-column ml--70">
            <v-img
              slot="thum-img"
              class="w-80"
              src="@/assets/images/service/metaforika.png"
              alt="About Images"
            />
          </div>
        </v-col>
        <v-col
          lg="12"
          md="12"
          sm="12"
          v-if="true"
          class="text-body-2 font-italic d-flex flex-column justify-center align-center"
          style="color:#5d83d2"
        >
          <span class="pb-4" style="width: 500px;"
            >(*) Οι πόντοι συμμετοχής στην κλήρωση έπικ23 αφαιρούνται από το
            σύνολο των πόντων του χρήστη/μέλους του προγράμματος και δεν
            επιστρέφονται μετά την ολοκλήρωση της κλήρωσης και ανάδειξης του
            νικητή.</span
          >
          <span class="pb-4" style="width: 500px;"
            >(**) Ισχύουν όροι & προϋποθέσεις</span
          >
        </v-col>
      </v-row>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/FooterTwo";
import Gifts from "@/components/gifts/Gifts";
import GiftCategories from "@/components/gifts/GiftCategories";
import RedeemCouponForm from "@/views/RedeemCouponForm";
import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";
import FlashMessage from "@smartweb/vue-flash-message";
import pdf from "vue-pdf";

export default {
  name: "categories",
  data() {
    return {
      catid: null,
      currentPage: 1,
      perPage: 5,
      errors: "",
      valid: true,
      dialog: false,
    };
  },
  props: ["categoriesChosen", "giftsChosen"],
  components: {
    Header,
    Footer,
    Gifts,
    GiftCategories,
    RedeemCouponForm,
    pdf,
  },
  computed: {
    ...mapGetters(["categories", "activeCategories","gifts","user"]),
    ...mapGetters(["gifts"]),
    ...mapGetters(["user"]),

    categoriesToShow() {
      let cats = [...(this.categories || [])];
      if (this.activeCategories.length) {
        return cats.filter((cat) =>{
            return this.activeCategories.includes(`${cat.gift_category_id}`)
          }
        );
      }
      return cats.sort((a, b) => a.gift_category_id - b.gift_category_id);
    },
    paramCats() {
      return this.$route.query.category
    }  
  },
  watch: {
    currentPage: async function(newQuestion, oldQuestion) {
      await this.updateCategory(this.catid, newQuestion);
    },
    paramCats: {
          immediate: true,
          handler(val) {
            if (val && val.length) {
              this.setActiveCategories(Array.isArray(val)? val : [val])
            }

          },
          deep: true,
      },
    categoriesToShow: {
          immediate: true,
          handler(val) {
              if (val && val[0] && val[0].gift_category_id ) {
                // this.updateCategory(val[0].gift_category_id)
              this.updateCategory(val[0].gift_category_id, 1);
              }else{
                this.setGifts([])
              }
          },
          deep: true,
      },
  },
  async created() {
      this.$loading(true);
    if (!this.gifts) {
      await this.getCategories();
    } else {
      await this.getCategories();
    }

    //  if(this.categories.length > 0){
    //  console.log(this.categories[0].gift_category_id,'this.categories')
    //   this.catid = this.categories[0].gift_category_id;
    //   if(!this.categories[0].gift_category_id){
    //     this.$loading(true);
    //     await this.getGifts([this.categories[0].gift_category_id, 1]);
    //     this.$loading(false);
    //   }else{
    //     await this.getGifts([this.categories[0].gift_category_id, 1]);
    //   }

    //  }
    await this.getUser();
    this.$loading(false);

  },
  methods: {
    ...mapActions(["getGifts", "getUser", "getCategories"]),
    ...mapMutations (["setActiveCategories","setGifts"]),
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
    async updateCategory(id, page) {
      this.$loading(true)
      this.catid = id;
      this.currentPage = page||1;
      console.log(id,this.currentPage)
      await this.getGifts([id, this.currentPage]);
      this.scrollToTop()
      this.$loading(false)

    },
    
    async redeemCoupon(couponCode) {
      try {
        this.$loading(true);
        const response = await axios
          .post("external/me/transactions/coupon-claim", {
            code: couponCode,
          })
          .then((response) => {
            this.$loading(false);
            let textAns =
              "<span>" +
              "Συγχαρητήρια!" +
              "</br>" +
              " Πήρες το δώρο!" +
              "</br>" +
              "Ο κωδικός είναι: " +
              "</br>" +
              response.data.data.voucher +
              "</span>";
            let testt = "Θα βρείτε τον κωδικό στην κατηγορία: <br> Τα δώρα μου";
            this.$swal({
              html: testt,
              title: textAns,
              icon: "success", //built in icons: success, warning, error, info
              // timer: 15000,
              buttons: {
                confirm: {
                  text: "OK",
                  value: true,
                  visible: true,
                  className: "",
                  closeModal: true,
                },
                cancel: {
                  text: "Cancel",
                  value: false,
                  visible: true,
                  className: "",
                  closeModal: true,
                },
              },
            });
          })
          .catch((error) => {
            this.$loading(false);
            this.$swal({
              title: "Αποτυχία",
              text: "Κάτι πήγε στραβά. Παρακαλώ ξαναπροσπαθήστε.",
              icon: "error", //built in icons: success, warning, error, info
              timer: 3000,
              buttons: {
                confirm: {
                  text: "OK",
                  value: true,
                  visible: true,
                  className: "",
                  closeModal: true,
                },
                cancel: {
                  text: "Cancel",
                  value: false,
                  visible: true,
                  className: "",
                  closeModal: true,
                },
              },
            });

            if (error.response.data.code == 401) {
              this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
            }
          });
      } catch (e) {
        this.error = e.message;
        return e;
      }
    },
  },
};
</script>

<style scoped>
.btn {
  background: #19b3e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  justify-content: left;
}
.isGrey {
  color: #ada7a7;
}
.isGreyNumber {
  color: #716c6c;
}
.isRed {
  color: #db3d4c;
}
a:hover {
  color: #2496d4 !important;
}
.v-application a {
  color: #104788;
  text-decoration: none !important;
}
p {
  font-size: 18px;
}
.container-xl {
  max-width: 1300px;
}
</style>
