<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @click:outside="dialog = false"

    >
      <template v-slot:activator="{ on, attrs }">
        <b-button
          color="primary"
          v-bind="attrs"
          v-on="on"
          :disabled="points > total_points"
        >
          Εξαργύρωση
        </b-button>
        <p class="isGrey" v-if="points > total_points">Δεν έχεις αρκετούς πόντους για αυτό το δώρο</p>
                                    
      </template>
      <v-card>
        <v-form ref="form"
            v-model="valid"
            lazy-validation>
          <v-card-title>
            <h4 style="color: #104788; font-size: 2rem"><b>Εξαργύρωση</b></h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="mt--30">
                      <h4>Στοιχεία Διεύθυνσης:</h4>
                        <v-col cols="12">
                          <v-text-field
                            label="Διεύθυνση *"
                            v-model="address" 
                            :rules="addressRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Όροφος *"
                            v-model="floor" 
                            :rules="floorRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Κουδούνι *"
                            v-model="bell_name" 
                            :rules="bellNameRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Ταχυδρομικός Κώδικας *"
                            v-model="postalCode" 
                            :rules="postalCodeRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Πόλη *"
                            v-model="city" 
                            :rules="cityRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                      <h4>Στοιχεία παραλήπτη:</h4>
                        <v-col cols="12">
                          <v-text-field
                            label="Όνομα *"
                            v-model="firstName" 
                            :rules="firstNameRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Επώνυμο *"
                            v-model="lastName" 
                            :rules="lastNameRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Κινητό Τηλέφωνο *"
                            v-model="mobile" 
                            :rules="mobileRules"
                            type="text"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Email *"
                            type="email"
                            :rules="emailRules"
                            v-model="email"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
            </v-container>
            <small>*Υποχρεωτικά πεδία</small>
            <p class="error" v-if="errors">{{ errors }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="dialog = false"
            >
              Κλείσιμο
            </v-btn>
            <v-btn
              text
              @click="redeemProduct(code)" 
            >
              Εξαργύρωση
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</template>


<script>
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import FlashMessage from '@smartweb/vue-flash-message';

  export default {
    name: 'redeemGifts',
    props: {
        code: String,
        promo_type: String,
        points: Number,
        total_points: Number,
    },
    data() {
        return  {
          errors: '',
            valid: true,
            dialog: false,
            mobile: '',
            floor: '',
            bell_name: '',
            city: '',
            postalCode: '',
            firstName: '',
            lastName: '',
            email: '',
            address: '',
            mobileRules: [
              v => !!v || 'Το κινητό τηλέφωνο είναι υποχρεωτικό',
              v => /^\d+$/.test(v) || 'Το κινητό τηλέφωνο πρέπει να περιέχει μόνο αριθμούς',
              v => (v && (""+v).length == 10) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς',
              v => (v && v.charAt(0) == 6 && v.charAt(1) == 9) || 'Το κινητό τηλέφωνο πρέπει να ξεκινάει απο 69',
            ],
            addressRules: [
              v => !!v || 'Η διεύθυνση είναι υποχρεωτική',
            ],
            postalCodeRules: [
              v => (!!v && /^\d{3}\s{0,1}\d{2}$/.test(v)) || 'Ο ταχυδρομικός κώδικας δεν είναι έγγυρος',
            ],
            cityRules: [
              v => !!v || 'Η πόλη είναι υποχρεωτική',
            ],
            firstNameRules: [
              v => !!v || 'Το όνομα είναι υποχρεωτικό',
            ],
            lastNameRules: [
              v => !!v || 'Το επίθετο είναι υποχρεωτικό',
            ],
            emailRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
              v => (!v || /.+@.+\..+/.test(v)) || 'Το email δεν είναι έγγυρο.',
            ],
            floorRules: [
              v => !!v || 'Ο όροφος είναι υποχρεωτικός',
              v => /^\d+$/.test(v) || 'Ο όροφος πρέπει να περιέχει μόνο αριθμούς',
            ],
            bellNameRules: [
              v => !!v || 'Το κουδούνι είναι υποχρεωτικό',
            ],
        }
    },
    methods: {
      async redeemProduct(couponCode){
        try{
          if(this.$refs.form.validate()){
            this.$loading(true);
            const response = await axios.post('external/me/transactions/coupon-claim', 
            {
                code: couponCode,
                shipping_details: {
                    recipient_address: {
                        address: this.address,
                        floor: this.floor,
                        bell_name: this.bell_name,
                        postal_code: this.postalCode,
                        city: this.city
                    },
                    recipient_information: {
                        first_name: this.firstName,
                        last_name: this.lastName,
                        mobile: this.mobile,
                        email: this.email,
                        email_1: this.email
                    }
              }
            }).then(response => {
                this.dialog = false;
                this.$loading(false);
                this.$swal({
                    title: "Συγχαρητήρια",
                    text: "Αποκτήσατε το δώρο επιτυχώς.",
                    icon: "success", //built in icons: success, warning, error, info
                    timer: 3000,
                      buttons: {
                        confirm: {
                          text: "OK",
                          value: true,
                          visible: true,
                          className: "",
                          closeModal: true
                        },
                        cancel: {
                          text: "Cancel",
                          value: false,
                          visible: true,
                          className: "",
                          closeModal: true,
                        }
                      }
                  });
            }).catch(error => {
                this.$loading(false);
                this.$swal({
                  title: "Αποτυχία",
                  text: "Κάτι πήγε στραβά. Παρακαλώ ξαναπροσπαθήστε.",
                  icon: "error", //built in icons: success, warning, error, info
                  timer: 3000,
                    buttons: {
                      confirm: {
                        text: "OK",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                      },
                      cancel: {
                        text: "Cancel",
                        value: false,
                        visible: true,
                        className: "",
                        closeModal: true,
                      }
                    }
                });

                if (error.response.data.code == 401) {
                    this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                } 
            });  
          }else{
            return false;
          }      
        }catch(e){
            this.error = e.message;
            return e;
        }
      }
    },
  };
</script>


<style scoped>
.btn {
    background: #19b3e6;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    justify-content: left;
}
.isGrey{
  color: #ada7a7;
}
.isGreyNumber{
  color: #716c6c;
}
.isRed{
  color: #db3d4c;
}
 a:hover {
    color: #2496d4 !important;
  }
  .v-application a {
    color: #104788;
    text-decoration: none !important;
  }
</style>